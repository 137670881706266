.page_hbb {
    .moreLink {
        color: navy;
        text-decoration: underline;
        cursor: pointer;
    }

    .videos a {
        background-color: rgb(255, 254, 191);
        color: rgb(0, 0, 0);
    }
    .britishNews a {
        color: rgb(2, 8, 48);
    }
    .americanNews a {
        color: rgb(21, 31, 97);
    }
    .australianNews a {
        color: rgb(53, 64, 138);
    }
    .germanNews a {
        color: rgb(12, 66, 12);
    }
    .austrianNews a {
        color: rgb(36, 110, 36);
    }
    .swissNews a {
        color: rgb(19, 82, 19);
    }

    .frenchNews a {
        color: rgb(85, 15, 79);
    }
    .canadaNews a {
        color: rgb(124, 40, 117);
    }

    /* mobile */
    @media only screen and (max-width: 368px) {
        .separationDot {
            font-size: 1rem;
            display: inline-block;
            &::before {
                content: "\00a0\25CF\00a0";
            }
            color: #888;
        }
        .linkItem a {
            font-size: 1.4rem;
        }
    }
    /* computer */
    @media only screen and (min-width: 369px) {
        .separationDot {
            font-size: 1rem;
            display: inline-block;
            &::before {
                content: "\00a0\25CF\00a0";
            }
            color: #888;
        }
        .linkItem a {
            display: inline-block;
            padding: 0 5px;
        }
    }
}
