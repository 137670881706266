$backgroundColor: #888;
$techGroupFontSize: 0.8em;
$extraSectionTitleFontSize: 1.4em;
$otherAreaTitleColor: #333;
.pageTech {
    .listTechItems {
        h3 {
            margin: 20px 0 0 0;
            background-color: $backgroundColor;
            color: ivory;
            padding: 0px 5px 5px 5px;
            border-radius: 5px;
        }
        .overview {
            margin-bottom: 10px;
            .genericTermIdCode {
                font-variant: small-caps;
                color: #aaa;
            }
            .header {
                margin-bottom: -2px;
            }
            .body {
                background-color: #d3d3d3;
                padding: 0px 4px 5px 7px;
                cursor: pointer;
            }
            .title {
                font-weight: 600;
                font-size: 1.2em;
            }
            .quick {
                color: brown;
                font-size: 0.9em;
                font-style: italic;
                margin-top: -2px;
            }
            .createDate {
                color: navy;
                font-weight: 500;
                font-size: 0.8em;
                text-transform: uppercase;
                &::after {
                    content: ' - ';
                }
            }
            a {
                text-decoration: none;
            }
        }
    }
    .item {
        .header {
            padding-bottom: 11px;
            .category {
                font-variant: small-caps;
                color: #888;
            }
            .quick {
                font-style: italic;
                color: brown;
                margin-top: -8px;
                line-height: 1.2em;
            }
            .editIcon {
                color: darkgreen;
                font-size: 0.8em !important;
                margin-top: 5px;
                margin-left: 5px;
            }
            .title {
                font-weight: 600;
                display: flex;
            }
        }
        .body {
            padding: 2px 20px 15px 0;
            span {
                font-size: 1.2em;
                line-height: 1px;
                cursor: pointer;
            }
        }
        .comparisonArea {
            background-color: #e2e2e2;
            img {
                height: auto;
                width: auto;
            }
            /* smartphone */
            @media (max-width: 767px) {
                padding: 8px 20px 20px 20px;
                .title {
                    margin-left: -11px;
                }
                .comparisonBodyArea {
                    margin-left: -26px;
                }
            }

            /* computer */
            @media (min-width: 768px) {
                padding: 20px;
            }
        }
        .techTopicGroupArea {
            padding: 20px;
            background-color: #d8d8d8;
            img.techTopicGroupImage {
                height: auto;
                width: auto;
            }
            /* smartphone */
            @media (max-width: 767px) {
                img.techTopicGroupImage {
                    max-width: 100%;
                    margin: 12px 0 20px -3px;
                }
            }

			.techTopicGroup {
				margin-bottom: 30px;
			}
            /* computer */
            @media (min-width: 768px) {
                img.techTopicGroupImage {
                    max-width: 90%;
                    margin: 12px 0 20px 25px;
                }
            }

            h3 {
                font-size: $extraSectionTitleFontSize;
                color: $otherAreaTitleColor;
            }
            .quick {
                font-style: italic;
            }
            .title {
                font-weight: 600;
			}
			ul.techItemsList a.techItemLink {
				background-color: #e5e5e5;
				display: inline-block;
				padding: 0 5px 3px 5px;
				line-height: 1.2em;
			}
            /* smartphone */
            @media (max-width: 767px) {
                .groupBody {
                    margin-left: -19px;
                }
                .title {
                    margin-left: -5px;
                }
                ul.techItemsList {
                    margin: -10px 10px 10px -29px;
                }
            }

            /* computer */
            @media (min-width: 768px) {
                .groupBody {
                    margin-left: 0;
                }
                ul.techItemsList {
                    margin: -10px 10px 10px 0px;
                }
            }
        }
        .comparison {
            padding: 7px;
            h3 {
                font-size: $extraSectionTitleFontSize;
                font-weight: 600;
                color: $otherAreaTitleColor;
            }
        }
        .relatedTechItem {
            color: $otherAreaTitleColor;
        }
        .versus {
            color: #555;
        }
    }
}
