.pageForays {
    .listItemForays {
        .overview {
            margin-bottom: 10px;
            .category {
                color: brown;
                font-weight: 500;
                font-variant: small-caps;
            }
            .createDate {
                color: navy;
                font-weight: 500;
                font-size: 0.8em;
                text-transform: uppercase;
                &::after {
                    content: ' - ';
                }
            }
            .header {
                margin-bottom: -2px;
            }
            .body {
                margin-bottom: 11px;
                span {
                    font-size: 1.2em;
                    line-height: 1px;
                    cursor: pointer;
                }
            }
            a {
                text-decoration: none;
            }
        }
    }
    .item {
        .editIcon {
            color: darkgreen;
            font-size: 0.8em !important;
            margin-top: 5px;
            margin-left: 5px;
        }
    }
}
