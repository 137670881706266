$frame_background_color: #ddd;
$text_muted: #aaa;
$link_muted: #555;

html,
body,
#App,
#App > div {
    height: 100%;
}

.App {
    text-align: left;
}

.mainContainer {
    width: 100%;
    padding: 10px 10px 80px 10px;
}

body {
    background-color: #eee !important;

    span.standardOutlineButton {
        padding: 0 5px;
        font-weight: bold;
        font-size: 1em !important;
        font-family: "Courier New", Courier, monospace;
        background-color: #ddd;
        border: 1px solid #bbb;
        border-radius: 3px;
        height: 20px;
    }

    .sitePageTitle {
        color: black !important;
        font-weight: normal;
        a {
            border-bottom: solid 1px #999;
            text-decoration: none;
        }
        a:hover {
            border-bottom: solid 1px #666;
        }
    }

    .container {
        margin: 80px 0;
        padding-bottom: 80px;
    }

    .topBar {
        height: 67px;
        background-color: #ddd !important;
    }

    .bottomBar {
        border-top: 1px solid #cfcfcf;
        height: 70px;
        background-color: #ddd !important;
        .version {
            color: #aaa;
            font-size: 0.8em;
        }
    }

    .navbar {
        border-bottom: 1px solid #cfcfcf;
        .appTitle {
            color: #555;
            font-weight: normal;
        }
        a.active {
            font-weight: bold;
        }
        .credits {
            .lead {
                text-align: center;
                font-size: 0.7em;
            }
            .name {
                text-align: center;
                font-size: 0.8em;
                text-decoration: underline;
                a {
                    color: navy;
                }
            }
            .leadAndName {
                font-size: 0.9em;
                .appName {
                    font-weight: 600;
                }
                a.author {
                    color: navy;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }
    }

    .pageContent {
        padding: 5px;
        margin: 70px 0 0 0;
        a {
            color: black;
            text-decoration: underline;
        }
    }

    .footer {
        background-color: #f8f9fa;
        height: 2em;
        padding-top: 0.2em;
        color: $text_muted;
        font-size: 0.8em;
        display: flex;
        justify-content: space-between;
        a {
            color: $link_muted;
            text-decoration: underline;
        }

        .left {
            padding-left: 10px;
        }

        .right {
            padding-right: 10px;
        }
    }

    button.btn-siteMenu {
        background-color: #eee;
        border: 1px solid #ccc;
        color: #333;
    }

    button.btn-onSite {
        background-color: #e6e6e6;
        border: 1px solid #bbb;
    }

    .btn-xs {
        padding: 0.4em !important;
        font-size: 0.8em !important;
        border-radius: 0.2rem !important;
        height: 30px;
    }

    .siteMenuArea {
        background-color: #e5e5e5;
        margin-bottom: 70px;
        padding: 10px;

        .menuItemButtonPublic {
            margin: 10px 10px;
            color: black;
        }

        .menuItemButtonLocal {
            margin: 10px 10px;
            color: tomato;
            font-style: italic;
            opacity: 0.6;
        }
    }

    .siteSearchArea {
        background-color: #e5e5e5;
        margin-bottom: 70px;
        padding: 10px;
    }
}

.elementShow {
    display: block;
}

.elementHide {
    display: none;
}

.prohibitSelectionClickable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.siteTitleLink {
    text-decoration: none;
    border-bottom: 1px solid #aaa;
}

.homeIcon {
    font-size: 22px;
}

// OUTLINE

ul.outline {
    list-style: disc;

    /* smartphone */
    @media (max-width: 767px) {
        margin: 1px 0 0 10px !important;
    }

    /* computer */
    @media (min-width: 768px) {
        margin: 1px 0 0 20px !important;
    }

    li {
        line-height: 19px;
        // margin: 0 0 0 0;
        margin-top: 10px;
        margin-left: -20px;
    }

    li code {
        color: brown;
        background-color: #ddd;
        font-weight: bold;
        font-family: "Courier New", Courier, monospace;
    }

    li a {
        color: black;
    }

    img {
        margin: 10px 0 10px 0;
        border: 1px solid #ddd;
        max-width: 100%;
    }

    li.codeBlock {
        list-style: none;
    }

    ul {
        margin: 0 0 0 0 !important;
    }

    div.codeBlock {
        color: #000;
        font-family: courier;
        font-size: 13px;
        padding: 5px 10px 5px 10px;
        background-color: #e5e5e5;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: 2px 0 0 -19px;

        pre {
            margin: 0 0 0 0;
        }
    }

    div.paragraphBlock {
        font-family: courier;
        font-size: 13px;
        padding: 5px 10px 5px 10px;
        background-color: rgb(243, 243, 220);
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: -4px 0 0 0;

        pre {
            color: #000 !important;
            margin: 0 0 0 0;
            white-space: normal;
        }
    }

    .video {
        max-width: 100%;
        margin: 10px 0 0 0;
    }
}

.item {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    border-radius: 5px;
    -webkit-box-shadow: 7px 5px 15px -8px #000000;
    box-shadow: 7px 5px 15px -8px #000000;

    .header {
        border-bottom: 1px solid #ccc;
        padding: 5px 15px;
        background-color: #ccc;
        z-index: 10;

        .category {
            color: brown;
            font-weight: 500;
            font-variant: small-caps;
        }

        .createDate {
            color: navy;
            font-weight: 500;
            font-size: 0.8em;
            text-transform: uppercase;
            &::after {
                content: " - ";
            }
        }

        .title {
            font-size: 24px;
            margin: 0 0 10px 0;
            line-height: 27px;
        }

        .manageIconArea {
            z-index: 0;
        }

        .headerRow {
            a {
                text-decoration: none;
            }
            .showExample {
                font-size: 18px;
                color: darkgreen;
                display: flex;
                margin-top: -5px;
                .text {
                    margin-top: -3px;
                    margin-left: 5px;
                }
            }
            .showDownload {
                font-size: 18px;
                color: rgb(1, 51, 1);
                display: flex;
                margin-top: -5px;
                .text {
                    margin-top: -3px;
                }
            }
            .subRow {
                display: flex;
                a {
                    margin-right: 20px;
                }
            }
        }
    }

    .body {
        background-color: #f3f3f3;

        /* smartphone */
        @media (max-width: 767px) {
            padding: 2px 20px 20px 0;
        }

        /* computer */
        @media (min-width: 768px) {
            padding: 10px 20px 20px 0;
        }

        pre {
            margin: 0;
        }

        .dataTypeWholeNumber_minus {
            color: tomato;
        }

        .labelPart {
            color: #aaa;
        }

        div.displayItemTextBlockArea {
            background-color: #ddd;
            padding: 5px;
            border-radius: 5px;
        }

        div.displayItemTextBlockArea textarea {
            font-size: 13px;
            height: 200px;
        }

        /* if you find you need this code, put it back in but in a way
        that does not effect the outline mode, e.g. see showcaseSearchAndDisplayItemType

        ul {
            margin    : 0 0 0 -10px;
            list-style: none;
        }

        ul li::before {
            content    : "\2022";
            color      : #aaa;
            font-weight: bold;
            display    : inline-block;
            width      : 1em;
            margin-left: -1em;
        }
        */

        table {
            border-spacing: 2px;
            border-collapse: separate;

            td.label {
                font-weight: bold;
                white-space: nowrap;
                vertical-align: top;
                color: #555;
            }

            td.data {
                background-color: #fff;
                width: 100%;
            }
        }

        /* smartphone */
        @media (max-width: 767px) {
            .video {
                width: 186px;
                height: 105px;
            }

            img.outlineImage {
                width: 100%;
            }
        }

        /* computer */
        @media (min-width: 768px) {
            .video {
                width: 560px;
                height: 315px;
            }

            img.outlineImage {
                max-width: 100%;
                height: auto;
                width: auto;
            }
        }
    }
}
