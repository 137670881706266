$disclaimer_background: #ddd;
$disclaimer_padding: 15px;
.pageIfc {
    .intro {
        font-style: italic;
        // display: flex;
        background: #ddd;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 0.9em;
        padding: 20px 20px 10px 20px;
        border-radius: 5px;
        .listContainer {
            margin-left: 10px;
        }
        /* smartphone view */
        @media (max-width: 577px) {
            img {
                width: 90px;
                float: left;
                margin: 0 10px 5px 0;
            }
        }

        /* computer/table view */
        @media (min-width: 578px) {
            img {
                width: 160px;
                float: left;
                margin: 0 20px 10px 0;
            }
        }
    }
    h1 {
        font-size: 1.3em;
        font-weight: bold;
        margin: 30px 0 20px 0;
    }
    ul {
        margin: 0 0 0 -20px;
        li {
            margin: 0 0 5px 0;
        }
    }
    div.disclaimer {
        display: flex;
        color: #555;
        flex-direction: column;
        margin: 20px 0 0 0;
        border: 1px solid $disclaimer_background;
        font-family: "Courier New", Courier, monospace;
        h1 {
            margin: 0;
            background-color: $disclaimer_background;
            padding: $disclaimer_padding;
        }
        div.body {
            padding: $disclaimer_padding;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
        }
        p {
            margin: 0;
        }
    }
    /* smartphone view */
    @media (max-width: 577px) {
        div.disclaimer {
        }
    }

    /* computer/table view */
    @media (min-width: 578px) {
        div.disclaimer {
            max-width: 800px;
            font-size: 13px;
        }
    }
}
