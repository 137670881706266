$titlesColor: #333;

.pageRigorousEthics {
	strong {
		font-weight: 600;
	}
	a {
		font-weight: 600;
		text-decoration: none !important;
		color: black !important;
		&:hover {
			color: navy !important;
			border-bottom: 1px solid navy;
		}
	}
	.devModeText {
		color: #bbb;
		font-size: 0.8em;
	}
	.subtitle {
		font-size: 1.3em;
		margin-bottom: 10px;
		color: $titlesColor;
		padding-bottom: 5px;
	}

	.description {
		margin-top: 10px;
	}
	.moreDescriptionLink {
		color: blue;
		.innerLink {
			&:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}
	.personItem {
		display: flex;
		justify-content: flex-start;
		margin-bottom: 20px;
		.selectedPodcasts {
			margin: 10px 0;
			background-color: #ddd;
			padding: 10px 15px;
			a.podcastLink {
				text-decoration: underline;
				border-bottom: 1px solid #888;
			}
		}
		.podcastLeader {
			color: brown;
			font-weight: bold;
			&::after {
				content: " ";
			}
		}
		.imageArea {
			font-size: 0.8em;
			background-color: #ddd;
			margin-right: 10px;
			text-align: center;
			img {
				width: 50px;
				filter: grayscale(100%);
			}

			.infoBlock {
				margin-top: 15px;
			}
		}
		/* smartphone view */
		@media (max-width: 577px) {
			.imageArea {
				padding: 7px;
				font-size: 0.8em;
				img {
					width: 50px;
				}
			}

			.fullName {
				font-size: 1.5em !important;
			}
			p {
				font-size: 0.9em;
			}

			.informationArea {
				.fullName {
					font-weight: 500;
					font-size: 1em;
				}
				p {
					font-size: 0.9em;
				}
			}
		}

		/* computer/table view */
		@media (min-width: 578px) {
			.imageArea {
				padding: 10px;
				font-size: 1.1em;
				img {
					width: 100px;
				}
			}

			.fullName {
				font-size: 1.4em !important;
				font-weight: 600 !important;
			}
			p {
				font-size: 1.1em;
			}
			.informationArea {
				.fullName {
					font-weight: 500;
					font-size: 1.2em;
				}
			}
		}
	}

	.researchItems,
	.minorPersons {
		font-family: "Courier New", Courier, monospace;
		h1 {
			font-size: 1.5em;
			color: #333;
			font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
			background-color: #ccc;
			padding: 5px;
			border-radius: 3px;
		}
		ul {
			list-style: none; /* Remove default bullets */

			li {
				margin-bottom: 10px;
			}
		}

		ul li::before {
			content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
			color: #999; /* Change the color */
			font-weight: bold; /* If you want it to be bold */
			display: inline-block; /* Needed to add space between the bullet and the text */
			width: 1em; /* Also needed for space (tweak if needed) */
			margin-left: -1em; /* Also needed for space (tweak if needed) */
		}

		.minorPersonItem {
			font-family: Arial, Helvetica, sans-serif !important;
		}
		.minorPersonItem > .fullName {
			color: black !important;
			font-weight: bold;
		}
		.minorPersonItem > .shortDescription {
			font-style: italic;
			color: black;
			background-color: #ddd;
		}
		.minorPersonItem > .description {
			font-style: normal;
		}
	}
}
