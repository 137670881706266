.pageMiniFrameworks {
    .itemBox {
        background-color: #ddd;
        padding: 10px;
        margin: 0 0 10px 0;
        border: 1px solid #ccc;
        border-radius: 5px;
        .title {
            font-size: 1.5em;
            font-weight: 500;
        }
        .createDate {
            color: #555;
        }
        .githubCode a {
            color: brown;
            font-weight: bold;
        }
        .idCode {
            color: black;
            font-weight: bold;
            font-family: "Courier New", Courier, monospace;
            background-color: #ccc;
        }
        .description {
            background-color: #eee;
            padding: 10px 10px 20px 0;
            margin-top: 10px;
            border-radius: 5px;
        }
        /* smartphone */
        @media (max-width: 767px) {
            .mainImage {
                width: 100%;
            }
        }

        @media (min-width: 768px) {
            .mainImage {
                height: auto;
                width: auto;
            }
        }
    }
}
