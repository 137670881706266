.pageShowcaseFlexPictureCards {

	.card {
		background: white;
		margin-bottom: 1em;

		.card-content {
			padding: 1.4em;

			h2 {
				margin-top: 0;
				margin-bottom: 0.5em;
				font-weight: normal;
			}
		}
	}

	/*computer-width*/
	@media screen and (min-width: 40em) {
		padding-top: 10px;
		margin-left: -10px;

		div.description {
			margin: 0 0 30px 20px;
		}

		.cards {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			&:after {
				content: "";
				flex: auto;
			}
		}
		.card {
			flex: 0 1 calc(50% - 1em);
			display: flex;
			margin-left: 18px;
		}
	}
	/*smartphone-width*/
	@media screen and (min-width: 60em) {

		padding-top: 10px;
		margin-left: -10px;

		div.description {
			margin: 0 0 30px 20px;
		}

		.card {
			flex: 0 1 calc(33% - 1em);
			margin-bottom: 40px;
		}
	}
}
