.pageHome {
	.theTitle {
		font-size: 1.7em;
		margin-bottom: 10px;
		letter-spacing: 1px;
		color: #999;
		font-variant: small-caps;
	}
	.dateTitle {
		font-style: italic;
		color: #000;
		background-color: #ddd;
		padding: 0 10px;
		margin-bottom: 5px;
	}
	.itemKind {
		color: brown;
	}
	.itemCategory {
		color: navy;
	}
	.itemTitle {
		color: black;
	}
	.itemLink {
		text-decoration: none;
		&:hover {
			background-color: #e4e4e4;
		}
	}

	ul {
		list-style: none;
		li {
			margin-left: -30px;
			margin-top: 10px;
			line-height: 1.2em;
		}
	}
	.todayText {
		color: rgb(216, 33, 0);
		font-weight: 500;
	}
	.yesterdayText {
		color: rgba(139, 0, 0, 0.782);
		font-weight: 500;
	}

	.testing333 {
		color: blue;
	}
}