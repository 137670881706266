.pageFitnessDays {
    .listItemFitnessDays {
        h1 {
            margin: -5px 0 10px 0;
            font-size: 1.5em;
        }
        h2 {
            margin: -5px 0 5px 0;
            font-size: 1.4em;
        }
        .category {
            color: brown;
            font-weight: 500;
            font-variant: small-caps;
        }
        .createDate {
            color: navy;
            font-weight: 500;
            font-size: 0.8em;
            text-transform: uppercase;
            &::after {
                content: ' - ';
            }
        }
        .header {
            margin-bottom: -2px;
        }
        .body {
            margin-bottom: 11px;
            span {
                font-size: 1.2em;
                line-height: 1px;
                cursor: pointer;
            }
		}
		.weight {
			font-weight: bold;
			color: brown;
		}
    }
}