$fadedFontColor: #777;

.pageWebDevCourse {
    .searchBox {
        margin: 0 0 20px 10px;
        ::placeholder {
            color: #ddd;
        }
    }
    h1 {
        margin: 0 0 5px 10px;
        text-shadow: 1px 1px #333;
        font-size: 1.5em;
        border: 1px solid #ccc;
        background-color: #ddd;
        padding: 3px 3px 6px 9px;
        color: lightyellow;
        background-color: #888;
    }
    @media screen and (max-width: 768px) {
        h1 {
            margin: 0 0 5px -5px;
        }
    }
    li.subunit {
        list-style-type: none;
        h2 {
            font-size: 1em;
            font-weight: 500;
            margin: 0 0 5px -10px;
            border: 1px solid #ccc;
            background-color: #ddd;
            padding: 3px;
            color: $fadedFontColor;
        }
        @media screen and (max-width: 768px) {
            h2 {
                margin: 0 0 5px -35px;
            }
        }
    }
    li.section {
        list-style-type: none;
        .dayLabel {
            background-color: #fff;
            line-height: 0.8em;
            margin: 0 0 5px -29px;
            padding: 4px 5px 5px 5px;
            display: inline-block;
            border-radius: 5px;
            color: brown;
            font-size: .8em;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
        }
        @media screen and (max-width: 768px) {
            .dayLabel {
                margin: 0 0 5px -59px;
            }
        }
        h3 {
            display: flex;
            .icon {
                font-size: 2.3em;
                color: #888;
                margin: 0 7px 0 5px;
            }
            .todoIcon {
                color: darkred;
            }
            color: black;
            font-size: 1em;
            font-weight: 500;
            margin: 0 0 5px -30px;
            border: 1px solid #ccc;
            background-color: beige;
            padding: 5px;
            span.overview {
                font-weight: normal;
                display: block;
                color: #999;
                font-style: italic;
            }
        }
        @media screen and (max-width: 768px) {
            h3 {
                margin: 0 0 5px -60px;
            }
        }
        .days {
            color: navy;
            display: inline-block;
            margin-left: 15px;
            background-color: #fff;
            &:before {
                content: " ";
                white-space: pre;
            }
            &:after {
                content: " ";
                white-space: pre;
            }
        }
        ul.sectionItems {
            margin: -5px 0 10px -20px;
            li {
                color: #555;
            }
        }
        @media screen and (max-width: 768px) {
            ul.sectionItems {
                margin: -5px 0 10px -70px;
            }
        }
    }
    h1:not(:first-child) {
        margin: 20px 0 0 10px;
    }
}
