.exampleArea {
	border: 1px solid #ccc;
	margin-top: 10px;
	background-color: beige;
	padding: 10px;

	h1 {
		font-size: 1.2em;
		font-family: 'Courier New', Courier, monospace;
		border-bottom: 1px solid #ccc;
	}
}