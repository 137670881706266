.pageCodeExercises {
    .listItemCodeExercises {
        .overview {
            margin-bottom: 10px;
            .category {
                color: brown;
                font-weight: 500;
                font-variant: small-caps;
            }
            .body {
                margin-bottom: 11px;
                span {
                    font-size: 1.2em;
                    line-height: 1px;
                    cursor: pointer;
                }
            }
        }
    }
    .item {
        .editIcon {
            color: darkgreen;
            font-size: 0.8em !important;
            margin-top: 5px;
            margin-left: 5px;
        }
        .btn-normal {
            background-color: #ccc;
            border: 1px solid #bbb;
        }
        button.hint {
            color: darkgreen;
            margin: 15px 0 0 40px;
        }
        button.solution {
            color: black;
            font-style: italic;
            margin: 15px 0 0 10px;
        }
        .hintArea {
            margin: 10px 0 0 40px;
            color: darkgreen;
            font-style: italic;
        }
        img {
            height: auto;
            width: auto;
        }
        img.hintImage {
            margin: 10px 0 0 40px;
        }
        .hintSolution {
            margin: 10px 0 -5px 0;
        }
        img.solutionImage {
            margin: 10px 0 0 40px;
        }
    }
}
