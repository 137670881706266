.pageQuizItems {
    .quizItem {
        margin: 0 0 30px 0;
        border: 1px solid #bbb;
        padding: 10px 20px 30px 20px;
        background-color: #ddd;
        border-radius: 5px;
        .category {
            color: brown;
            font-weight: 500;
            font-variant: small-caps;
            margin-bottom: -6px;
        }
        .description {
            font-size: 1.5em;
        }
        img {
            height: auto;
            width: auto;
        }
        .markedWord {
            background-color: yellow;
            font-weight: bold;
            color: red;
        }
    }
    .answerArea {
        background-color: beige;
        margin: 25px 10px 0 10px;
        border-radius: 5px;
        padding: 10px;
        h2 {
            font-size: 1.4em;
            color: #555;
        }
        .answer {
            font-family: "Courier New", Courier, monospace;
            font-size: 0.9em;
            display: inline-block;
            margin-right: 5px;
            margin-bottom: 3px;
            border: 1px solid #ddd;
            padding: 0 6px;
            font-weight: bold;
            background-color: #eee;
            -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
            box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.75);
        }
        .codeLink {
            margin-top: 3px;
        }
    }
}
