$placeholderColor: #ddd;

.spinner {
	// animation: spinner 1.5s linear infinite;
	animation: spin infinite 2s linear;
	font-size: 3em;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

// placeholder
#mainSearch::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: $placeholderColor;
	opacity: 1; /* Firefox */
}

#mainSearch:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: $placeholderColor;
}

#mainSearch::-ms-input-placeholder {
	/* Microsoft Edge */
	color: $placeholderColor;
}

.siteMessage {
	background: #333;
	color: #ccc;
	padding: 20px;
	border-radius: 5px;
	margin-bottom: 10px;
	font-size: 1.5rem;
	a {
		color: yellow !important;
	}
}
