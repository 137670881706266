.manageTable {
	$tableHoverBackgroundColor: #c4c4c4;
	$tableHoverFontColor: #000;

	overflow-x: auto;

	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;

		th,
		td {
			text-align: left;
			padding: 8px;
			vertical-align: top;
			cursor: pointer;
		}

		td.dpodTypeParagraph {
			min-width: 300px;
		}

		tbody {
			tr {
				color: #555;
			}
			tr:nth-child(even) {
				background-color: #e2e2e2;
				&:hover {
					// background-color: $tableHoverBackgroundColor;
					color: $tableHoverFontColor;
				}
			}

			tr:nth-child(odd) {
				background-color: rgb(211, 211, 211);
				&:hover {
					// background-color: $tableHoverBackgroundColor;
					color: $tableHoverFontColor;
				}
			}
		}
	}
}

h2.pageTitle > span {
	cursor: pointer;
	color: #555;
	&:hover {
		color: #000;
	}
}

.manageItemCard {
	max-width: 700px;

	dd {
		background-color: #eee;
	}
}

.toggleId {
	text-decoration: underline;
	cursor: pointer;
}