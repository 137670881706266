$menuReduced: #ddd;

.flashcard {
	margin: 0 0 10px 0;
	display: flex;
	justify-content: left;

	.infoArea {
		margin-left: 5px;
	}

	.category {
		text-transform: uppercase;
	}

	.categoryKind {
		font-size: 0.75em;
		color: green;
	}

	.front {
		font-weight: normal;
		font-size: 0.8em;
	}

	.back {
		color: brown;
		font-family: "Courier New", Courier, monospace;
		font-size: 0.8em;
		span {
			background-color: #ddd;
		}
	}
}

.searchArea {
	margin: 0 0 0px 0;

	.buttonRow {
		margin: 0 5px 15px 5px;
		display: flex;
		flex-wrap: wrap;
	}

	.searchRow {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}

	.btn-topic {
		margin: 0 5px 5px 0;
	}

	.searchBox {
		margin: 0 5px 0 0;
	}

	.separator {
		color: $menuReduced;
	}

	.category {
		cursor: pointer;
	}

	.active {
		background: $menuReduced;
		font-weight: bold;
		&:before { 
			content: " ";
			white-space: pre;
		}
		&:after { 
			content: " ";
			white-space: pre;
		 }
	}

	.category:hover {
		background-color: $menuReduced;
	}
}

/* smartphone view */
@media (max-width: 577px) {
	.searchBox {
		width: 240px;
	}
}

/* computer/table view */
@media (min-width: 578px) {
	.searchBox {
		width: 400px;
	}
}
