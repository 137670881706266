.page_about {
    div.panel {
        padding: 0 10px 10px 10px;
        margin-top: -20px;
    }

    div.intro {
        font-style: italic;
        font-size: 1.3em;
        padding: 0 20px;
    }

    div.content p:nth-child(1) {
        margin-top: 0;
    }

    @media screen and (max-width: 768px) {
        div.content {
            margin-top: 20px;
        }
        h1 {
            font-size: 1.4em;
            margin-top: 25px;
        }
        div.intro {
            font-size: 0.9em;
            padding: 0;
        }
        img.author {
            width: 100px;
            float: left;
            margin-right: 15px;
            border-radius: 5px;
        }
    }

    @media screen and (min-width: 769px) {
        div.content {
            margin-top: 20px;
            column-count: 2;
            column-gap: 30px;
        }
        img.author {
            width: 200px;
            float: left;
            margin-right: 15px;
            border-radius: 5px;
        }
    }

    @media screen and (min-width: 1281px) {
        div.content {
            margin-top: 20px;
            column-count: 3;
            column-gap: 40px;
        }
        img.author {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}
